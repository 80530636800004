<template>
  <v-card
    class="kpi"
    v-if="account"
  >
    <v-card-title>{{$t('t.KeyPerformanceIndicators')}}</v-card-title>
    <v-card-text>
      <grid
        class="flex-grow-1 kpi-grid"
        item-min-width="130px"
      >
        <spark
          v-for="({item, reverse, title}, index) in kpiList"
          class="full-width"
          :key="index"
          :item="item"
          :reverse="reverse"
          :title="title"
        />
      </grid>
    </v-card-text>
    <v-card-text>
      <div
        class="d-flex flex-column"
        v-if="account.paymentTerms"
      >
        <span class="text-subtitle-1">{{$t('t.PaymentTerms')}}</span>
        <span class="text-body-1">{{account.paymentTerms}}</span>
      </div>
      <div
        class="d-flex flex-column"
        v-if="account.paymentMethod"
      >
        <span class="text-subtitle-1">{{$t('t.PaymentMethod')}}</span>
        <span class="text-body-1">{{account.paymentMethod}}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    Grid: () => import('@/components/grid'),
    Spark: () => import('./spark')
  },
  computed: {
    kpiList () {
      return [
        { item: this.account.std, reverse: true, title: this.$t('t.AnnualDSO') },
        { item: this.account.countBack, reverse: true, title: this.$t('t.CountBackDSOToday') },
        { item: this.account.best, reverse: true, title: this.$t('t.AnnualBestDSO') },
        { item: this.account.delinquent, reverse: false, title: this.$t('t.AnnualDelinquentDSO') },
        { item: this.account.ddo, reverse: false, title: this.$t('t.AnnualDDO') },
        { item: this.account.dbtTrend, reverse: true, title: this.$t('t.AnnualDBTTrend') },
        { item: this.account.dbtHisto, reverse: true, title: this.$t('t.AnnualDBTHisto') },
        { item: this.account.cei, reverse: false, title: this.$t('t.AnnualCEI') }
      ]
    }
  },
  props: {
    account: Object
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%

.kpi-grid
  justify-items center

.v-card__text
  display flex
  justify-content space-around
  gap 2em
  flex-direction row
  flex-wrap wrap
</style>
